<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span class="vol-event-name">安全进万家 幸福你我他</span>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <a @click="$router.back(-1)" href="javascript:void()">
                {{ typeTitle }}</a
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">活动详情</li>
          </ol>
        </nav>
        <div class="vol-event-name">
          {{ info.title }}
        </div>
        <div class="vol-event-des">
          <div class="vol-event-time">{{ info.created_at }}</div>
          <div style="display: flex; flex-direction: row">
            <div class="vol-event-div">浏览量 {{ info.flowrate }}</div>
            <div style="margin-left: 40px" class="vol-event-div">
              已报名 {{ info.apply_counts }}
            </div>
          </div>
        </div>
        <div class="vol-event-intro">
          <div class="intro-item">
            <div><i class="iconfont icon-weibiaoti-3"></i>活动地点</div>
            <div>
              {{ info.city + info.district + info.street + info.address }}
            </div>
          </div>
          <div class="intro-item">
            <div><i class="iconfont icon-shijian"></i>活动时间</div>
            <div>{{ info.start_time }}-{{ info.end_time }}</div>
          </div>
          <div class="intro-item">
            <div><i class="iconfont icon-geren"></i>组织者</div>
            <div>{{ info.management }}</div>
          </div>
        </div>
        <div class="vol-event-member">
          <div class="vol-member-title">
            报名志愿者（{{ members.total }}）
            <!-- <view class="vol-more" bindtap="toNewsList">
              查看更多 <i class="iconfont icon-youjiantou"></i>
            </view> -->
          </div>

          <div class="member-list">
            <div class="member-list-scroll">
              <div
                class="member-item"
                v-for="item in members.list"
                :key="item.apply_id"
              >
                <div class="member-headshot">
                  <img :src="item.member.avatar" mode="aspectFill" />
                </div>
                <view class="member-name">{{ item.member.real_name }}</view>
              </div>
            </div>
          </div>
        </div>
        <div class="rich-text">
          <div v-html="decodeContent(info.intro)"></div>
          <div id="container" class="video-wrapper">
            <div
              id="vidro-content"
              v-for="item in info.sections"
              :key="item.section_id"
              v-show="item.vid"
            ></div>
          </div>
        </div>
        <!-- 未报名 -->
        <div class="footer-bottom">
          <div class="left">
            <button class="btn btn-link" @click="toFavourite">
              <i
                :class="[
                  'iconfont',
                  info.is_favourite == '1'
                    ? 'icon-favourite_selected'
                    : 'icon-favourite_normal',
                ]"
              ></i>
            </button>
            <!-- <button class="btn btn-link"><i class="iconfont icon-favourite_selected"></i></button> -->
          </div>
          <div class="right">
            <div v-if="isEnded">
              <div class="bottom-btn disabled" v-if="isEnded">活动结束</div>
            </div>
            <div v-else-if="userInfo.member_id != info.member_id">
              <div
                class="bottom-btn"
                @click="toJoinEvent"
                v-if="info.apply_status == 0"
              >
                我要报名
              </div>
              <label class="state state-ing" v-if="info.apply_status == 1">
                报名审核中
              </label>
              <label class="state state-fail" v-if="info.apply_status == -1">
                报名未通过
              </label>
              <div
                class="bottom-btn"
                @click="toJoinEvent"
                v-if="info.apply_status == -1"
              >
                重新报名
              </div>
              <div
                class="bottom-btn"
                @click="toClockIn"
                v-if="info.apply_status == 2 && info.is_clock_in == 0"
              >
                活动打卡
              </div>
              <div
                class="bottom-btn"
                @click="toClockIn"
                v-if="info.apply_status == 2 && info.is_clock_in == 1"
              >
                已打卡
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  eventDetail,
  eventFavourite,
  eventApply,
  myOrgEventMemberList,
} from "@/network/API.js";
import "../../utils/txplayer.js";
export default {
  data() {
    return {
      type: 0,
      videoId: "",
      info: {},
      userInfo: this.$store.getters.userInfo,
      player: null,
      isEnded: false,
      typeTitle: "",
      members: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    }
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
      if (this.type == "1") {
        this.typeTitle = "省级专项活动";
      }
      if (this.type == "2") {
        this.typeTitle = "地市精彩活动";
      }
      if (this.type == "3") {
        this.typeTitle = "找活动";
      }
    }
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    async getDetail(eid) {
      let data = await eventDetail(eid);
      let memberList = await myOrgEventMemberList({
        event_id: this.$route.params.id,
        apply_status: 2,
      });
      this.info = data;
      this.members = memberList;
      this.info.sections.forEach((element) => {
        if (element.vid.length > 0) {
          this.playerInit(element.vid);
        }
      });
      let curDate = new Date().getTime();
      let endDate = new Date(data.end_time).getTime();
      if (endDate < curDate) {
        this.isEnded = true;
      }
    },
    playerInit(vid) {
      this.player = new Txplayer({
        containerId: "container",
        vid: vid,
        width: "100%",
        height: screen.availHeight / 2,
        autoplay: true,
      });
    },
    async toFavourite() {
      let loader = this.$loading.show();
      let res = await eventFavourite({
        id: this.info.event_id,
      });
      if (res.is_favourite == 1) {
        this.$toast("收藏成功");
      } else {
        this.$toast("取消收藏");
      }
      this.info.is_favourite = res.is_favourite;
      loader.hide();
    },
    async toJoinEvent() {
      if (
        this.userInfo.is_volunteer_group == "1" &&
        this.data.user.volunteer_group &&
        this.data.user.is_volunteer_group.member_id == this.data.user.member_id
      ) {
        this.$toast("组织负责人不可以报名活动");
        return;
      }
      let res = await eventApply({
        event_id: this.info.event_id,
      });
      this.getDetail();
    },
    toClockIn() {
      if (this.isEnded) {
        this.$toast("活动已结束");
        return;
      }
      this.$toast("请前往小程序端打卡");
      // this.$router.push({
      //   name: "VolClockIn",
      //   params: {
      //     id: this.info.event_id,
      //   },
      // });
    },
  },
};
</script>
<style>
.vol-hearts {
  top: -210px;
}
.vol-event-name {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.6em;
  margin-bottom: 20px;
}
.vol-event-cover {
  position: relative;
  z-index: 2;
  margin-bottom: -30px;
}

.vol-linear {
  height: 360px;
}

.vol-event-cover image {
  display: block;
  width: 750px;
  height: 390px;
}

.vol-event-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #9e9e9e;
  margin-bottom: 30px;
}
.vol-event-member {
  border: 1px solid #ff3241;
  margin-bottom: 30rpx;
  padding: 20px;
  border-radius: 20px;
}

.vol-event-intro {
  border-radius: 10px;
  background: linear-gradient(110deg, #ffb990 0%, #ff3241 100%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}

.intro-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.intro-item:last-child {
  margin-bottom: 0;
}

.intro-item .iconfont {
  margin-right: 5px;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom .left,
.footer-bottom .right {
  display: flex;
  align-items: center;
}

.footer-bottom .right {
  flex: 1;
  justify-content: flex-end;
}

.footer-bottom .iconfont {
  font-size: 38px;
  color: #ff3241;
  background: linear-gradient(123deg, #ffb990 1%, #ff3241 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-bottom .bottom-btn {
  width: 260px;
  background: linear-gradient(123deg, #ffb990 1%, #ff3241 100%);
  margin-left: 15px;
  border-radius: 50px;
}

.footer-bottom .right .state {
  white-space: nowrap;
  font-size: 18px;
  color: #ff3241;
}
.footer-bottom .right .state.state-success {
  color: #00be6e;
}
.btn-link {
  text-decoration: none;
}
.disabled {
  opacity: 0.5;
}

.member-list {
  display: flex;
  overflow-x: auto;
}
.member-list-scroll {
  display: flex;
  align-items: flex-start;
}
.member-item {
  width: 110px;
  overflow: hidden;
  text-align: center;
  font-size: 28px;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.member-headshot {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}
.member-headshot img {
  width: 90px;
  height: 90px;
}
</style>
